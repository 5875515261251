import React, { useEffect, useState, ReactNode, createContext, useCallback } from 'react';
import CloseIcon from '../../icons/CloseIcon';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import clsx from 'clsx';
import LoadingGif from '../../images/loading/loading.gif';
import RefreshIcon from '../../icons/Refresh';
import useGalleryPhotoContext from '../../hooks/useGalleryPhotoContext/useGalleryPhotoContext';
import DoneIcon from '../../icons/Done';
import Traducciones from '../Traducciones/Traducciones';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        chatWindowContainer: {
            background: '#FFFFFF',
            display: 'flex',
            flexDirection: 'column',
            borderLeft: '1px solid #E4E7E9',
            position: 'fixed',
            top: 0,
            right: 0,
            height: '100vh',
            width: '25vw',
            zIndex: 100,
        },
        hide: {
            display: 'none !important' ,
        },
      container: {
        height: '56px',
        background: '#F4F4F6',
        borderBottom: '1px solid #E4E7E9',
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
        padding: '0 1em',
      },
      text: {
        fontWeight: 'bold',
      },
      closeChatWindow: {
        cursor: 'pointer',
        display: 'flex',
        background: 'transparent',
        border: '0',
        padding: '0.4em',
      },
      contenedorImages:{
        display: 'grid',
        gridTemplateColumns: 'repeat(2, 1fr)',
        gridTemplateRows: 'repeat(2, 1fr)',
        gap: '1rem',
        padding: '0.5rem',
        overflow: 'auto',
      },
      seccionImagen:{
        position: 'relative',
        width: '100%',
        height: '13vh',
        overflow: 'hidden',
      },
      seccionIndividual:{
        position: 'absolute',
        top: '0',
        left: '0',
        width: '100%',
        height: '100%',
        backgroundColor: 'rgba(0, 0, 0, 0.4)', 
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      },
      elementoImagen:{
        width: '100%',
        height: '100%',
        objectFit: 'cover',
      },
      imagenLoading:{
        width: '3vw',
      },
      alineadoMensajeRecargar:{
        display: 'grid',
        justifyItems: 'center',
        alignItems: 'center',
      },
      textoIntentardeNuevo:{
        textAlign: 'center',
        color: '#dfdfdf',
        textShadow: '2px 2px 2px #141414'
      },
      seccionIndividualDone:{
        top: '3px',
        right: '3px',
        display: 'flex',
        position: 'absolute',
        justifyContent: 'center',
        filter: 'drop-shadow( 1px 1px 2px rgba(0, 0, 0, .6))',
      },
      contenedorDone:{
        backgroundColor: 'white',
        height: '32px',
        borderRadius: '5px',
      }
    })
  );
export interface FotoTomada {
  fotografia:any;
  urlfotografia:string;
  sid:string;
  urlroom:string;
  latitud:any;
  longitud:any;
  altitud:any;
  clave_inspeccion:any;
  cargado:boolean;
  enviando:boolean;
  id:string;
}
export default function GalleryPhotoWindow() {
    const classes = useStyles();
    const {arregloFotoTomada, setArregloFotoTomada } = useGalleryPhotoContext();
    const [arregloTotalFoto, setArregloTotalFoto] = useState(Array<FotoTomada>);

    const rawImagePaths = [
        LoadingGif,
      ];

    const {seMuestraGaleria, setSeMuestraGaleria} = useGalleryPhotoContext();
    const cambiarEstatusModal = () =>{
        setSeMuestraGaleria(false);
    };

    useEffect(() => {
      if(arregloFotoTomada.length > 0){
        arregloTotalFoto.push(arregloFotoTomada[0]);
        setArregloTotalFoto(arregloTotalFoto);
        setArregloFotoTomada([]);
        actualizarEstatusEnvio(arregloFotoTomada[0],true,true)
        .then((resultado) => {
          if(Array.isArray(resultado)){
            setArregloTotalFoto(resultado);
            setArregloFotoTomada([]);
          }else{
            let errorCargaFotografia = ponerEstatusFotografiaReintentar(arregloFotoTomada[0]);
            if(Array.isArray(errorCargaFotografia)){
              setArregloTotalFoto(errorCargaFotografia);
              setArregloFotoTomada([]);
            }
          }
        })
        .catch((error) =>{
          let errorCargaFotografia = ponerEstatusFotografiaReintentar(arregloFotoTomada[0]);
          if(Array.isArray(errorCargaFotografia)){
            setArregloTotalFoto(errorCargaFotografia);
            setArregloFotoTomada([]);
          }
        });
      }
    },);
    

    
    const actualizarEstatusEnvio = async (arregloFotoTomada:FotoTomada, estatusCargado:boolean, estatusEnviando:boolean) => {
      return new Promise((resolve, reject) => {
          try {
            let imageBlob: any = undefined;
              var formdata = new FormData();
              formdata.append('sid', arregloFotoTomada.sid);
              formdata.append('urlroom', arregloFotoTomada.urlroom);
              const urlguarda =
                process.env.API_URL ??
                'https://inspeccion-remota-api.charlestaylor.com.mx/api/consulta-usuario-llamada';
              fetch(urlguarda, {
                method: 'POST',
                body: formdata,
              })
              .then(response => response.json())
              .then(data => {
                arregloFotoTomada.fotografia.toBlob(blob => {
                  imageBlob = arregloFotoTomada.fotografia.toDataURL('image/png');
                  formdata.append('idinspeccion', '');
                  formdata.append('observacion_seccion', '-');
                  formdata.append('tituloSeccion', '-sin dato-');
                  formdata.append('observacion_fotografia_seccion', '-sin dato-');
                  formdata.append('latitud', data.data.latitude);
                  formdata.append('longitud', data.data.longitude);
                  formdata.append('altitud', data.data.altitude);
                  formdata.append('idreporte_inspeccion', '');
                  formdata.append('fotografia', blob, 'archivo.png');
                  formdata.append('conreunion', 'si');
                  formdata.append('clave_inspeccion', arregloFotoTomada.clave_inspeccion);
                  /* formdata.append('errortest', ""); */
                  var requestOptions = {
                    method: 'POST',
                    body: formdata,
                  };
                  const urlguarda =
                    process.env.API_URL ??
                    'https://inspeccion-remota-api.charlestaylor.com.mx/api/carga-fotografia-reporte';
                  fetch(urlguarda, {
                    method: 'POST',
                    body: formdata,
                  })
                    .then(response => {
                      if(response.status === 200){
                        arregloTotalFoto.forEach(fotos => {
                          if (fotos.id === arregloFotoTomada.id) {
                            fotos.cargado = estatusCargado;
                            fotos.enviando = estatusEnviando;
                          }
                        });
                        resolve(arregloTotalFoto);
                      }else{
                        reject([]);
                      }
                    })
                    .catch(error => {
                      reject([]);
                    });
                });
              });
          } catch (error) {
            reject([]);
          }
        });
    };

    const ponerEstatusFotografiaReintentar = (arregloFotoTomada:FotoTomada) => {
      try {
        arregloTotalFoto.forEach(fotos => {
          if (fotos.id === arregloFotoTomada.id) {
            fotos.cargado = false;
            fotos.enviando = true;
          }
        });
        return arregloTotalFoto;
      } catch (error) {
        return [];
      }
    };

    const cargaIndividualError = (idfotografia:string) => {
      try {
        let fotografiaEnviar:FotoTomada = {
          fotografia:"",
          urlfotografia:"",
          sid:"",
          urlroom:"",
          latitud:0,
          longitud:0,
          altitud:"",
          clave_inspeccion:"",
          cargado:false,
          enviando:false,
          id:"",
        };
        arregloTotalFoto.forEach(foto => {
          if(foto.id === idfotografia){
            fotografiaEnviar = foto;
            foto.cargado = false;
            foto.enviando = false;
          }
        });
        setArregloTotalFoto(arregloTotalFoto);
        setArregloFotoTomada([]);
        
        if(fotografiaEnviar.id !== ""){
          actualizarEstatusEnvio(fotografiaEnviar,true,true)
          .then((resultado) => {
            if(Array.isArray(resultado)){
              setArregloTotalFoto(resultado);
              setArregloFotoTomada([]);
            }else{
              let errorCargaFotografia = ponerEstatusFotografiaReintentar(fotografiaEnviar);
              if(Array.isArray(errorCargaFotografia)){
                setArregloTotalFoto(errorCargaFotografia);
                setArregloFotoTomada([]);
              }
              
            }
          })
          .catch((error) =>{
            let errorCargaFotografia = ponerEstatusFotografiaReintentar(fotografiaEnviar);
            if(Array.isArray(errorCargaFotografia)){
              setArregloTotalFoto(errorCargaFotografia);
              setArregloFotoTomada([]);
            }
          });
        }
      } catch (error) {
        console.log("error");
        console.log(error);
      }
    };
    
    return (
        <aside className={clsx(classes.chatWindowContainer, { [classes.hide]: !seMuestraGaleria })}>
            <div className={classes.container}>
                <div className={classes.text}>{Traducciones('galeriafotos')}</div>
                <button className={classes.closeChatWindow} onClick={cambiarEstatusModal}>
                    <CloseIcon />
                </button>
            </div>
            <div className={classes.contenedorImages}>
                {arregloTotalFoto.map((fotografia,indexfotografia) => {
                  return <section className={classes.seccionImagen} key={fotografia.id}>
                      <img className={classes.elementoImagen} src={fotografia.urlfotografia} />
                      <div className={clsx(classes.seccionIndividual, {[classes.hide] : (fotografia.cargado && fotografia.enviando) || (!fotografia.cargado && fotografia.enviando)})}>
                        <img className={classes.imagenLoading} src={rawImagePaths[0]} />
                      </div>
                      <div className={clsx(classes.seccionIndividualDone, {[classes.hide] : (!fotografia.cargado && !fotografia.enviando) || (!fotografia.cargado && fotografia.enviando)})}>
                        <div className={classes.contenedorDone}>
                          <DoneIcon/>
                        </div>
                      </div>
                      <div className={clsx(classes.seccionIndividual, {[classes.hide] : (!fotografia.cargado && !fotografia.enviando) || (fotografia.cargado && fotografia.enviando)})}>
                        <div className={classes.alineadoMensajeRecargar} onClick={() => cargaIndividualError(fotografia.id)}>
                          <RefreshIcon/>
                          <label className={classes.textoIntentardeNuevo}> {Traducciones('clickparareintentar')} </label>
                        </div>
                      </div>
                  </section>
                })}
            </div>
        </aside>
    );
}