import React, { useState } from 'react';
import { createStyles, makeStyles, Theme, styled } from '@material-ui/core/styles';

import Button from '@material-ui/core/Button';
import EndCallButton from '../Buttons/EndCallButton/EndCallButton';
import { isMobile } from '../../utils';
import Menu from './Menu/Menu';
import useParticipants from '../../hooks/useParticipants/useParticipants';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import useVideoContext from '../../hooks/useVideoContext/useVideoContext';
import { Typography, Grid, Hidden } from '@material-ui/core';
import ToggleAudioButton from '../Buttons/ToggleAudioButton/ToggleAudioButton';
import ToggleChatButton from '../Buttons/ToggleChatButton/ToggleChatButton';
import ToggleVideoButton from '../Buttons/ToggleVideoButton/ToggleVideoButton';
import ToggleScreenShareButton from '../Buttons/ToogleScreenShareButton/ToggleScreenShareButton';
import useIsRecording from '../../hooks/useIsRecording/useIsRecording';
import { useAppState } from '../../state';
import StartRecordingIcon from '../../icons/StartRecordingIcon';
import StopRecordingIcon from '../../icons/StopRecordingIcon';
import { LocalVideoTrack, connect } from 'twilio-video';
import Traducciones from './../Traducciones/Traducciones';

export const IconContainer = styled('div')({
  display: 'flex',
  justifyContent: 'center',
  width: '1.5em',
  marginRight: '0.3em',
});
const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      backgroundColor: theme.palette.background.default,
      bottom: 0,
      left: 0,
      right: 0,
      height: `${theme.footerHeight}px`,
      position: 'fixed',
      display: 'flex',
      padding: '0 1.43em',
      zIndex: 10,
      [theme.breakpoints.down('sm')]: {
        height: `${theme.mobileFooterHeight}px`,
        padding: 0,
      },
    },
    screenShareBanner: {
      position: 'fixed',
      zIndex: 8,
      bottom: `${theme.footerHeight}px`,
      left: 0,
      right: 0,
      height: '104px',
      background: 'rgba(0, 0, 0, 0.5)',
      '& h6': {
        color: 'white',
      },
      '& button': {
        background: 'white',
        color: theme.brand,
        border: `2px solid ${theme.brand}`,
        margin: '0 2em',
        '&:hover': {
          color: '#600101',
          border: `2px solid #600101`,
          background: '#FFE9E7',
        },
      },
    },
    hideMobile: {
      display: 'initial',
      [theme.breakpoints.down('sm')]: {
        display: 'none',
      },
    },
    botonesFlotantes: {
      border: 'solid 1px red',
      margin: '0 1vh 0 1vh',
    },
    botonSinGrabar: {
      border: 'solid 1px #707578',
      margin: '0 1vh 0 1vh',
    },
  })
);

export default function MenuBar() {
  const classes = useStyles();
  const { isSharingScreen, toggleScreenShare } = useVideoContext();
  const roomState = useRoomState();
  const isReconnecting = roomState === 'reconnecting';
  const { room } = useVideoContext();
  const participants = useParticipants();
  const { isFetching, updateRecordingRules, roomType, setIsGalleryViewActive, isGalleryViewActive } = useAppState();
  const isRecording = useIsRecording();
  const [latitude, setlatitude] = useState(Number);
  const [longitud, setlongitud] = useState(Number);
  const [altitude, setaltitude] = useState(Number);
  //getlocation();

  /* function getlocation() {
    try {
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function(position) {
          setlatitude(position.coords.latitude);
          setlongitud(position.coords.longitude);
          setaltitude(position.coords.altitude ? position.coords.altitude : 0);
        });
      }
      //console.log('location que importa');
      //console.log('latitude ', latitude);
      //console.log('longitud ', longitud);
      //console.log('altitude ', altitude);
    } catch (error) {
      console.log('Error al obtener localización');
      //console.log(error);
    }
  } */
  return (
    <>
      {isSharingScreen && (
        <Grid container justifyContent="center" alignItems="center" className={classes.screenShareBanner}>
          <Typography variant="h6">Estas compartiendo la pantalla</Typography>
          <Button onClick={() => toggleScreenShare()}>Detener compartir pantalla</Button>
        </Grid>
      )}
      <footer className={classes.container}>
        <Grid container justifyContent="space-around" alignItems="center">
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Typography variant="body1">
                {/* {room!.name} | */} {participants.length + 1} {Traducciones('participante')}
                {participants.length ? 's' : ''}
              </Typography>
            </Grid>
          </Hidden>
          <Grid item>
            <Grid container justifyContent="center">
              <ToggleAudioButton disabled={isReconnecting} />
              <ToggleVideoButton disabled={isReconnecting} />
              {/* <Button
                onClick={async () => {
                  alert('hola :v');
                  // let canvas = document.createElement('canvas');
                  // let video = document.getElementsByName('video');
                  // console.log(video);

                  // canvas.width = 1920;
                  // canvas.height = 1080;

                  // let ctx = canvas.getContext('2d');
                  // // ctx?.drawImage( video, 0, 0, canvas.width, canvas.height );

                  // let image = canvas.toDataURL('image/jpeg');
                  const capture = async () => {
                    // const canvas = document.createElement("canvas");
                    // const context = canvas.getContext("2d");
                    const video = document.createElement('video');

                    let canvas = document.createElement('canvas');
                    const context = canvas.getContext('2d');
                    // let element: HTMLVideoElement;
                    // let video =   document.getElementById('video');
                    try {
                      const captureStream = await navigator.mediaDevices.getDisplayMedia();
                      if (video != null) {
                        //   video.getAttribute('src');
                        video.srcObject = captureStream;
                        context?.drawImage(video, 0, 0, 800, 600);
                        const frame = canvas.toDataURL('image/jpeg');
                        captureStream.getTracks().forEach(track => track.stop());
                        window.location.href = frame;
                      }
                    } catch (err) {
                      console.error('Error: ' + err);
                    }
                  };

                  capture();
                }}
              >
                Tomar foto
              </Button> */}
              <Button
                className={isRecording ? classes.botonesFlotantes : classes.botonSinGrabar}
                disabled={isFetching}
                onClick={() => {
                  if (isRecording) {
                    updateRecordingRules(room!.sid, [{ type: 'exclude', all: true }]);
                  } else {
                    updateRecordingRules(room!.sid, [{ type: 'include', all: true }]);
                  }
                }}
                data-cy-recording-button
              >
                <IconContainer>{isRecording ? <StopRecordingIcon /> : <StartRecordingIcon />}</IconContainer>
                <Typography variant="body1">
                  {isRecording ? Traducciones('detener') : Traducciones('grabar')}
                </Typography>
              </Button>
              {/* <Button>Ubicación</Button> */}
              {/* {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />} */}
              {/* {!isSharingScreen && !isMobile && <ToggleScreenShareButton disabled={isReconnecting} />} */}
              {process.env.REACT_APP_DISABLE_TWILIO_CONVERSATIONS !== 'true' && <ToggleChatButton />}
              <Hidden smDown>
                <Menu />
              </Hidden>
            </Grid>
          </Grid>
          <Hidden smDown>
            <Grid style={{ flex: 1 }}>
              <Grid container justifyContent="flex-end">
                <EndCallButton />
              </Grid>
            </Grid>
          </Hidden>
        </Grid>
      </footer>
    </>
  );
}
