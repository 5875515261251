import React, { useState } from 'react';

const dictionario = {
  nombresistema: {
    esp: 'Inspección remota',
    eng: 'Remote inspection',
    por: 'Inspeção remota',
  },
  unirsealavideollamada: {
    esp: 'Unirse a la videollamada',
    eng: 'Join the video call',
    por: 'Participe da videochamada',
  },
  introducetunombreylaclavedeaccesodelainspeccion: {
    esp: 'Introduce tu nombre y la clave de acceso de la inspección',
    eng: 'Enter your name and inspection access code',
    por: 'Digite seu nome e código de acesso à inspeção',
  },
  nombredelusuario: {
    esp: 'Nombre del usuario',
    eng: 'Username',
    por: 'Nome do usuário',
  },
  clavedeacceso: {
    esp: 'Clave de acceso',
    eng: 'Access code',
    por: 'Código de acesso',
  },
  continuar: {
    esp: 'Continuar',
    eng: 'Continue',
    por: 'Prosseguir',
  },
  salir: {
    esp: 'Salir',
    eng: 'Leave',
    por: 'Saia',
  },
  unirsealainspeccion: {
    esp: 'Unirse a la inspección',
    eng: 'Join the inspection',
    por: 'Participe da inspeção',
  },
  audio: {
    esp: 'Audio',
    eng: 'Audio',
    por: 'Áudio',
  },
  camara: {
    esp: 'Cámara',
    eng: 'Camera',
    por: 'Câmera',
  },
  ajustes: {
    esp: 'Ajustes',
    eng: 'Settings',
    por: 'Configurações',
  },
  masajustes: {
    esp: 'Más Ajustes',
    eng: 'More settings',
    por: 'Mais configurações',
  },
  cancelar: {
    esp: 'Cancelar',
    eng: 'Cancel',
    por: 'Cancelar',
  },
  unirse: {
    esp: 'Unirse',
    eng: 'Join',
    por: 'Participe',
  },
  ajustesaudioyvideo: {
    esp: 'Ajustes Audio y Video',
    eng: 'Audio and Video Settings',
    por: 'Configurações de áudio e vídeo',
  },
  video: {
    esp: 'Video',
    eng: 'Video',
    por: 'Vídeo',
  },
  entradadevideo: {
    esp: 'Entrada de Video',
    eng: 'Video Input',
    por: 'Entrada de vídeo',
  },
  entradadeaudio: {
    esp: 'Entrada de Audio',
    eng: 'Audio input',
    por: 'Entrada de áudio',
  },
  salidadeaudio: {
    esp: 'Salida de Audio',
    eng: 'Audio output',
    por: 'Saída de áudio',
  },
  vistaencuadro: {
    esp: 'Vista en cuadro',
    eng: 'Box view',
    por: 'Vista de caixa',
  },
  maximosparticipantes: {
    esp: 'Máximos Participantes',
    eng: 'Maximum Participants',
    por: 'Máximo de Participantes',
  },
  hecho: {
    esp: 'Hecho',
    eng: 'Done',
    por: 'Feito',
  },
  uniendosealavideoinspeccion: {
    esp: 'Uniendose a la video inspección',
    eng: 'Joining the video inspection',
    por: 'Juntando-se à inspeção de vídeo',
  },
  errorenlascredenciales: {
    esp: 'Error en las credenciales',
    eng: 'Credentials error',
    por: 'erro de credenciais',
  },
  expandir: {
    esp: 'Expandir',
    eng: 'Expand',
    por: 'Expandir',
  },
  ubicacion: {
    esp: 'Ubicación',
    eng: 'Location',
    por: 'Localização',
  },
  tomarfotografia: {
    esp: 'Tomar fotografía',
    eng: 'Take a photo',
    por: 'Tire uma foto',
  },
  participante: {
    esp: 'Participante',
    eng: 'Participant',
    por: 'Participante',
  },
  grabar: {
    esp: 'Grabar',
    eng: 'Record',
    por: 'Gravar',
  },
  detener: {
    esp: 'Detener',
    eng: 'Stop',
    por: 'Pare',
  },
  chat: {
    esp: 'Chat',
    eng: 'Chat',
    por: 'Chat',
  },
  masopciones: {
    esp: 'Más Opciones',
    eng: 'More options',
    por: 'Mais opções',
  },
  desconectarse: {
    esp: 'Desconectarse',
    eng: 'Disconnect',
    por: 'Desconectar',
  },
  link: {
    esp: 'Link',
    eng: 'Link',
    por: 'Link',
  },
  fondosdepantalla: {
    esp: 'Fondos de pantalla',
    eng: 'Wallpapers',
    por: 'Papéis de parede',
  },
  compartirpantalla: {
    esp: 'Compartir pantalla',
    eng: 'Share screen',
    por: 'Compartilhar tela',
  },
  sehacopiadoellinkdelavideollamada: {
    esp: 'Se ha copiado el link de la videollamada',
    eng: 'The video call link has been copied',
    por: 'O link da videochamada foi copiado',
  },
  tu: {
    esp: 'Tu',
    eng: 'You',
    por: 'Vocês',
  },
  escribeunmensaje: {
    esp: 'Escribe un mensaje...',
    eng: 'Write a message...',
    por: 'Escreve uma mensagem...',
  },
  imagenesdefondo: {
    esp: 'Imágenes de fondo',
    eng: 'Backgrounds',
    por: 'Imagens de fundo',
  },
  lagrabacionhacomenzado: {
    esp: 'La grabación ha comenzado',
    eng: 'Recording has started',
    por: 'A gravação começou',
  },
  lagrabacionsehacompletado: {
    esp: 'La grabación se ha completado',
    eng: 'Recording is complete',
    por: 'A gravação está completa',
  },
  fotoguardadaexitosamente: {
    esp: 'Foto guardada exitosamente',
    eng: 'Photo saved successfully',
    por: 'Foto salva com sucesso',
  },
  falloalcopiarellinkdelavideollamada: {
    esp: 'Fallo al copiar el link de la videollamada',
    eng: 'Failed to copy the link of the video call',
    por: 'Falha ao copiar o link da videochamada',
  },
  error: {
    esp: 'Error',
    eng: 'Error',
    por: 'Erro',
  },
  grabacionenprogreso: {
    esp: 'Grabación en progreso',
    eng: 'Recording in progress',
    por: 'Gravação em andamento',
  },
  conexionperdida: {
    esp: 'Conexión perdida:',
    eng: 'Connection Lost:',
    por: 'Conexão perdida:',
  },
  reconectandoalasala: {
    esp: 'Reconectando a la sala...',
    eng: 'Reconnecting to room...',
    por: 'Reconectando ao quarto...',
  },
  reconectando: {
    esp: 'Reconectando...',
    eng: 'Reconnecting...',
    por: 'Reconectando...',
  },
  nosepuedeaccederalosmedios: {
    esp: 'No se puede acceder a los medios:',
    eng: 'Unable to Access Media:',
    por: 'Não foi possível acessar a mídia:',
  },
  elusuariohadenegadoelpermisoparausarelvideoconcedapermisoalnavegadorparaaccederalacamara: {
    esp: 'El usuario ha denegado el permiso para usar el video. Conceda permiso al navegador para acceder a la cámara',
    eng: 'The user has denied permission to use video. Please grant permission to the browser to access the camera',
    por: 'O usuário negou permissão para usar o vídeo. Conceda permissão ao navegador para acessar a câmera',
  },
  elusuariohadenegadoelpermisoparausarelaudioconcedapermisoalnavegadorparaaccederalmicrofono: {
    esp: 'El usuario ha denegado el permiso para usar el audio. Conceda permiso al navegador para acceder al micrófono',
    eng: 'The user has denied permission to use audio. Please grant permission to the browser to access the microphone',
    por: 'O usuário negou permissão para usar o áudio. Conceda permissão ao navegador para acessar o microfone',
  },
  elusuariohadenegadoelpermisoparausaraudioyvideootorguepermisoalnavegadorparaaccederalmicrofonoylacamara: {
    esp:
      'El usuario ha denegado el permiso para usar audio y video. Otorgue permiso al navegador para acceder al micrófono y la cámara',
    eng:
      'The user has denied permission to use audio and video. Please grant permission to the browser to access the microphone and camera',
    por:
      'O usuário negou permissão para usar áudio e vídeo. Conceda permissão ao navegador para acessar o microfone e a câmera',
  },
  elnavegadornopuedeaccederalmicrofonoolacamaraaseguresedequetodoslosdispositivosdeentradaestenconectadosyhabilitados: {
    esp:
      'El navegador no puede acceder al micrófono o la cámara. Asegúrese de que todos los dispositivos de entrada estén conectados y habilitados',
    eng:
      'The browser cannot access the microphone or camera. Please make sure all input devices are connected and enabled',
    por:
      'O navegador não pode acessar o microfone ou a câmera. Certifique-se de que todos os dispositivos de entrada estejam conectados e ativados',
  },
  losdemasparticipantesenlasalanopodranverloniescucharlo: {
    esp: 'Los demás participantes en la sala no podrán verlo ni escucharlo',
    eng: 'Other participants in the room will be unable to see and hear you',
    por: 'Os outros participantes na sala não poderão ver e ouvir você',
  },
  otrosparticipantesenlasalanopodranverte: {
    esp: 'Otros participantes en la sala no podrán verte',
    eng: 'Other participants in the room will be unable to see you',
    por: 'Os outros participantes na sala não poderão vê-lo',
  },
  otrosparticipantesenlasalanopodranescucharte: {
    esp: 'Otros participantes en la sala no podrán escucharte',
    eng: 'Other participants in the room will be unable to hear you',
    por: 'Os outros participantes na sala não poderão ouvi-lo',
  },
  ajustesdeaudioyvideo: {
    esp: 'Ajustes de Audio y Video',
    eng: 'Audio and Video Settings',
    por: 'Configurações de áudio e vídeo',
  },
  idioma: {
    esp: 'Idioma',
    eng: 'Language',
    por: 'Idioma',
  },
  cambiarcamara: {
    esp: 'Cambiar cámara',
    eng: 'Change camera',
    por: 'trocar de câmera',
  },
  galeriafotos: {
    esp: 'Galería Fotográfica',
    eng: 'Photo Gallery',
    por: 'Galeria de Fotos',
  },
  clickparareintentar: {
    esp: 'Clic para Reintentar la Carga',
    eng: 'Click to Retry Upload',
    por: 'Clique para Tentar Novamente o Carregamento',
  },
};

function Traducciones(palabraATraducir) {
  const idiomaSelecionado = localStorage.getItem('idiomaSelecionado') || 'esp';

  return dictionario[palabraATraducir][idiomaSelecionado];
}

export default Traducciones;
