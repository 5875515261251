import { useContext } from 'react';
import { seMuestraGaleriaContext } from '../../components/Room/Room';

export default function useGalleryPhotoContext() {
  const context = useContext(seMuestraGaleriaContext);
  if (!context) {
    throw new Error('useGalleryPhotoContext must be used within a GalleryPhotoContext');
  }
  return context;
}
