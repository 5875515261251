import React, { useEffect } from 'react';
import { Button, makeStyles, Menu, MenuItem, Theme, Typography } from '@material-ui/core';
import Swoosh from './swoosh';
import VideoLogo from './VideoLogo';
import TwilioLogo from './TwilioLogo';
import { useAppState } from '../../state';
import UserMenu from './UserMenu/UserMenu';
import { useLocation } from 'react-router-dom';
import Traducciones from './../Traducciones/Traducciones';

const useStyles = makeStyles((theme: Theme) => ({
  background: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    background: 'rgb(40, 42, 43)',
    height: '100%',
  },
  container: {
    position: 'relative',
    flex: '1',
  },
  innerContainer: {
    display: 'flex',
    width: '888px',
    height: '379px',
    borderRadius: '8px',
    boxShadow: '0px 2px 4px 0px rgba(40, 42, 43, 0.3)',
    overflow: 'hidden',
    position: 'relative',
    margin: 'auto',
    [theme.breakpoints.down('sm')]: {
      display: 'block',
      height: 'auto',
      width: 'calc(100% - 40px)',
      margin: 'auto',
      maxWidth: '400px',
    },
  },
  swooshContainer: {
    position: 'relative',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundImage: Swoosh,
    backgroundSize: 'cover',
    width: '296px',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      height: '100px',
      backgroundPositionY: '140px',
    },
  },
  logoContainer: {
    position: 'absolute',
    width: '210px',
    textAlign: 'center',
    [theme.breakpoints.down('sm')]: {
      display: 'flex',
      alignItems: 'center',
      width: '90%',
      textAlign: 'initial',
      '& svg': {
        height: '64px',
      },
    },
  },
  twilioLogo: {
    position: 'absolute',
    top: 0,
    left: 0,
    margin: '20px',
  },
  content: {
    background: 'white',
    width: '100%',
    padding: '3em 4em',
    flex: 1,
    [theme.breakpoints.down('sm')]: {
      padding: '2em',
    },
  },
  title: {
    color: 'white',
    margin: '1em 0 0',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '1.1rem',
    },
  },
  divTraducir: {
    position: 'absolute',
    right: '1vh',
    display: 'flex',
    alignItems: 'baseline',
    bottom: '0',
  },
  botonTraducir: {
    color: 'white',

    '&:hover': {
      color: '#c3c3c3',
      backgroundColor: 'rgba(0, 0, 0, 0)',
    },
  },
  tituloIdioma: {
    color: 'white',
    margin: '1em 0 0',
    fontSize: '0.9rem',
    [theme.breakpoints.down('sm')]: {
      margin: 0,
      fontSize: '0.9rem',
    },
  },
}));

interface IntroContainerProps {
  children: React.ReactNode;
}

const IntroContainer = (props: IntroContainerProps) => {
  const classes = useStyles();
  const { user } = useAppState();
  const location = useLocation();
  const [desplegaMenu, setdesplegaMenu] = React.useState<null | HTMLElement>(null);
  const [idiomaActual, setidiomaActual] = React.useState(null);

  useEffect(() => {
    try {
      if (localStorage.getItem('idiomaSelecionado')) {
        ponerIdioma(localStorage.getItem('idiomaSelecionado'));
      } else {
        localStorage.setItem('idiomaSelecionado', 'esp');
        setidiomaActual('Español');
      }
    } catch (error) {
      localStorage.setItem('idiomaSelecionado', 'esp');
      setidiomaActual('Español');
    }
  });

  //setidiomaActual("Español");

  const desplegarMenuTraducciones = (event: React.MouseEvent<HTMLButtonElement>) => {
    setdesplegaMenu(event.currentTarget);
  };
  const handleClose = (opcion: string) => {
    try {
      switch (opcion) {
        case 'esp':
        case 'eng':
        case 'por':
          if (ponerIdioma(opcion)) {
            setdesplegaMenu(null);
            localStorage.setItem('idiomaSelecionado', opcion);
            window.location.replace('');
          }
          break;
      }
      setdesplegaMenu(null);
    } catch (error) {
      setdesplegaMenu(null);
    }
  };

  function ponerIdioma(opcion: string): boolean {
    try {
      switch (opcion) {
        case 'esp':
          setidiomaActual('Español');
          break;
        case 'eng':
          setidiomaActual('English');
          break;
        case 'por':
          setidiomaActual('Português');
          break;
      }
      return true;
    } catch (error) {
      return false;
    }
  }

  return (
    <div className={classes.background}>
      {/* <TwilioLogo className={classes.twilioLogo} /> */}
      {user && location.pathname !== '/login' && <UserMenu />}
      <div className={classes.container}>
        <div className={classes.innerContainer}>
          <div className={classes.swooshContainer}>
            <div className={classes.logoContainer}>
              <VideoLogo />
              <Typography variant="h6" className={classes.title}>
                {Traducciones('nombresistema')}
              </Typography>
            </div>
            <div className={classes.divTraducir}>
              <Typography variant="h6" className={classes.tituloIdioma}>
                {Traducciones('idioma')}:
              </Typography>
              <Button
                aria-controls="simple-menu"
                aria-haspopup="true"
                onClick={desplegarMenuTraducciones}
                className={classes.botonTraducir}
              >
                {idiomaActual}
              </Button>
              <Menu
                id="simple-menu"
                anchorEl={desplegaMenu}
                keepMounted
                open={Boolean(desplegaMenu)}
                onClose={handleClose}
              >
                <MenuItem onClick={() => handleClose('esp')}>Español</MenuItem>
                <MenuItem onClick={() => handleClose('eng')}>English</MenuItem>
                <MenuItem onClick={() => handleClose('por')}>Português</MenuItem>
              </Menu>
            </div>
          </div>
          <div className={classes.content}>{props.children}</div>
        </div>
      </div>
    </div>
  );
};

export default IntroContainer;
