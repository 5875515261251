import React from 'react';
import Snackbar from '../Snackbar/Snackbar';
import useRoomState from '../../hooks/useRoomState/useRoomState';
import Traducciones from './../Traducciones/Traducciones';

export default function ReconnectingNotification() {
  const roomState = useRoomState();

  return (
    <Snackbar
      variant="error"
      headline={Traducciones('conexionperdida')}
      message={Traducciones('reconectandoalasala')}
      open={roomState === 'reconnecting'}
    />
  );
}
